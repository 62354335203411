button,
input {
  cursor: pointer;
}

.queue-logo {
  width: 25vw;
  padding-bottom: 100px;
}

.appointment {
  width: 30vw;
  position: absolute;
  left: 5vw;
  padding: 20px;
  overflow: auto;
  height: 75vh;
}

.appointment::-webkit-scrollbar {
  display: none;
}

.checkin::-webkit-scrollbar {
  display: none;
}

.queue::-webkit-scrollbar {
  display: none;
}

.checkin {
  width: 50vw;
  position: absolute;
  right: 5vw;
  padding: 20px;
  overflow: auto;
  height: 75vh;
}

.queue {
  width: 50vw;
  position: absolute;
  left: 5vw;
  padding: 20px;
  overflow: auto;
  /*height: 580px;*/
}

.queue #table th {
  font-size: 25px;
}

.queue #table td {
  font-size: 26px;
}

.store-name {
  font-size: 1.5rem;
  position: absolute;
  top: 43px;
  right: 30px;
  font-weight: 100;
  color: white;
}

.appointment-content {
  width: 100%;
  padding-top: 20px;
}

.checkin-content {
  width: 100%;
  padding-top: 20px;
}

.appointment-name {
  font-size: 1.5rem;
  font-weight: 900;
  /*color: rgb(177, 106, 104);*/
  color: #5dc11f;
}

.checkin-name {
  font-size: 1.5rem;
  font-weight: 900;
  /*color: rgb(149, 170, 109);*/
  color: #5dc11f;
}

.queue-name {
  font-size: 60px;
  font-weight: 900;
  color: #5dc11f;
}

.username {
  width: 100%;
}

.password {
  width: 100%;
}

.login {
  width: 330px;
}

.logout {
  width: 26px;
  padding-left: 20px;
  cursor: pointer;
}

a {
  text-decoration: none;
}

#login-result {
  padding: 30px;
  font-size: 20px;
  color: red;
}

.link_queue,
.link_home {
  width: 200px;
  padding: 30px;
  margin: auto;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-decoration: none;
  font-family: "Lato-Reg";
  font-size: 20pt;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.call-customer {
  position: absolute;
  right: 20px;
  top: 130px;
  width: 800px;
}

.customer-name {
  font-size: 60px;
}

.customer-queue {
  padding-top: 60px;
  font-size: 135px;
}

.form-control {
  margin-left: -1.5rem;
  height: 60px;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1.75rem;
  background: rgba(67, 34, 167, 0.4);
  color: #fff !important;
}

.button-text {
  color: #ffffff;
  text-decoration: none;
  font-family: "Lato-Reg";
  font-size: 20pt;
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.form-control:focus {
  outline: none;
}

.notification {
  width: 17px;
  padding: 0px 20px;
  cursor: pointer;
  margin-top: 2px;
  position: absolute;
}

.smartphone {
  width: 25px;
  padding: 4px 20px 2px 20px;
  cursor: pointer;
  position: relative;
}

.login-submit {
  color: #ffffff;
  background: transparent;
  border: solid 1px white;
  padding: 15px 60px;
  margin-top: 32px;
  display: inline-block;
  text-align: center;
  font-size: 2.15rem;
  outline: none;
}

.login-back {
  color: #ffffff;
  background: transparent;
  border: solid 1px white;
  padding: 5px 30px;
  margin-top: 20px;
  display: inline-block;
  text-align: center;
  font-size: 1.8rem;
  outline: none;
}

.login-submit:hover {
  cursor: pointer;
  background-color: #4a9141;
  box-shadow: 0 0 5px white;
  border-radius: 6px;
}

.login-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  color: #1db15c;
}

.video-panel .logo {
  padding: 100px;
  width: 35vw;
}

.video-panel {
  position: absolute;
  top: 130px;
}

#remote-media-div {
  position: absolute;
  width: 965px;
  height: 724px;
  background-color: #ffffff00;
  right: 20px;
}

#table thead,
#table tr {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgb(235, 242, 224);
}

#table {
  border-bottom-width: 0px;
  border-bottom-style: solid;
  border-bottom-color: rgb(235, 242, 224);
}

/* Padding and font style */
#table td {
  padding: 5px 10px;
  font-size: 1.15rem;
  font-family: Verdana;
  /*color: rgb(149, 170, 109);*/
  color: #ffffff;
  border: 1px solid;
}

#table th {
  padding: 5px 10px;
  font-size: 1.35rem;
  font-family: Verdana;
  /*color: rgb(149, 170, 109);*/
  color: #5dc11f;
  border: 1px solid #ffffff;
}

/* Alternating background colors */
#table tr:nth-child(even) {
  /*background: rgb(230, 238, 214)*/
}

#table tr:nth-child(odd) {
  /*background: #FFF*/
}

#table-2 thead,
#table-2 tr {
  border-top-width: 1px;
  border-top-style: solid;
  /*border-top-color: rgb(230, 189, 189);*/
  border-top-color: #ffffff;
}

#table-2 {
  border-bottom-width: 0px;
  border-bottom-style: solid;
  /*border-bottom-color: rgb(230, 189, 189);*/
  border-bottom-color: #ffffff;
}

table {
  border-collapse: separate;
  border-spacing: 0px;
}

/* Padding and font style */
#table-2 td {
  padding: 5px 10px;
  font-size: 1.15rem;
  font-family: Verdana;
  /*color: rgb(177, 106, 104);*/
  color: #ffffff;
  border: 1px solid;
}

#table-2 th {
  padding: 5px 10px;
  font-size: 1.35rem;
  font-family: Verdana;
  /*color: rgb(177, 106, 104);*/
  color: #5dc11f;
  border: 1px solid #ffffff;
}

/* Alternating background colors */
#table-2 tr:nth-child(even) {
  /*background: rgb(238, 211, 210)*/
}

#table-2 tr:nth-child(odd) {
  /*background: #FFF*/
}

@-webkit-keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }
  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@-webkit-keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ball-spin-fade-loader {
  50% {
    opacity: 0.3;
    -webkit-transform: scale(0.4);
    transform: scale(0.4);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ball-spin-fade-loader > div:nth-child(1) {
  top: 50px;
  left: 0;
  -webkit-animation: ball-spin-fade-loader 1s -0.96s infinite linear;
  animation: ball-spin-fade-loader 1s -0.96s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(2) {
  top: 34.04545px;
  left: 34.04545px;
  -webkit-animation: ball-spin-fade-loader 1s -0.84s infinite linear;
  animation: ball-spin-fade-loader 1s -0.84s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(3) {
  top: 0;
  left: 50px;
  -webkit-animation: ball-spin-fade-loader 1s -0.72s infinite linear;
  animation: ball-spin-fade-loader 1s -0.72s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(4) {
  top: -34.04545px;
  left: 34.04545px;
  -webkit-animation: ball-spin-fade-loader 1s -0.6s infinite linear;
  animation: ball-spin-fade-loader 1s -0.6s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(5) {
  top: -50px;
  left: 0;
  -webkit-animation: ball-spin-fade-loader 1s -0.48s infinite linear;
  animation: ball-spin-fade-loader 1s -0.48s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(6) {
  top: -34.04545px;
  left: -34.04545px;
  -webkit-animation: ball-spin-fade-loader 1s -0.36s infinite linear;
  animation: ball-spin-fade-loader 1s -0.36s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(7) {
  top: 0;
  left: -50px;
  -webkit-animation: ball-spin-fade-loader 1s -0.24s infinite linear;
  animation: ball-spin-fade-loader 1s -0.24s infinite linear;
}

.ball-spin-fade-loader > div:nth-child(8) {
  top: 34.04545px;
  left: -34.04545px;
  -webkit-animation: ball-spin-fade-loader 1s -0.12s infinite linear;
  animation: ball-spin-fade-loader 1s -0.12s infinite linear;
}

.ball-spin-fade-loader > div {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
}
.connect {
  /*position: absolute;*/
  /*margin-left: 60px;*/
  margin-top: 23px;
  /*right: 5vw;*/
  /*bottom: 35px;*/
}
.connect-status {
  width: 40px;
  float: left;
  font-size: 20px;
}
.connect-true {
  /*width: 20px;*/
  /*height: 20px;*/
  color: #5dc11f;
  /*border-radius: 10px;*/
  /*position: absolute;*/
  margin-top: 5px;
}
.connect-fales {
  color: #494949;
  /*border-radius: 10px;*/
  /*position: absolute;*/
  margin-top: 5px;
}

@-webkit-keyframes flashing {
  0% {
    opacity: 0;
    filter: alpha(opacity=0)
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100)
  }
}

@keyframes flashing {
  0% {
    opacity: 0;
    filter: alpha(opacity=0)
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100)
  }
}

@-webkit-keyframes borderFlashing {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: "#5dc11f";
  }
}

@keyframes borderFlashing {
  0% {
    border-color: transparent;
  }
  100% {
    border-color: "#5dc11f";
  }
}
